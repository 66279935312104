import * as React from "react"
import type { HeadFC, PageProps } from "gatsby"
import { graphql, Link } from "gatsby"

import Headers from '../components/Headers'

import Layout from "../components/Layout"

import bannerImage from '../images/hero team.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons'
import { faChartLine } from '@fortawesome/free-solid-svg-icons'
import SwissFlag from "../images/ch.png"
import FadeInSection from "../components/FadeInSection"
import Demo from "../components/Demo"

export const query = graphql`
query BusinessQuery($lang: String!) {
    markdownRemark(
      fields: {lang: {eq: $lang}}
      fileAbsolutePath: {regex: "/business.md/"}
    ) {
      frontmatter {
        pageTitle
        title
        description
        subtitle
        ctaButton
        feature1Title
        feature1Text
        feature2Title
        feature2Text
        feature3Title
        feature3Text
      }
      html
    }
  }
`

const IndexPage = ({data, pageContext}: any) => {

    data = data.markdownRemark

    const sourcesLabel = pageContext.lang == 'en' ? 'Sources' : 'Quellen'

    return (
        <Layout lang={pageContext.lang}>
            <div className="banner business">
                <div style={{flex:1, maxWidth: 800, margin: '0 auto'}}>
                    <h1>{ data.frontmatter.title }</h1>
                    <p>{ data.frontmatter.subtitle }</p>
                    <Link to="#demo">
                        <button className="cta" style={{marginBottom: 60}}>{ data.frontmatter.ctaButton}</button>
                    </Link>
                </div>

                <img src={bannerImage} alt="Mental Health for Everyone"/>
            </div>

            <div className="subbanner">
                <div dangerouslySetInnerHTML={{ __html: data.html }} />

                <div className="featureContainer">
                    <FadeInSection speed="fast">
                    <div className="feature">
                        <div className="roundIcon" style={{backgroundColor: '#DA291C'}}>
                            <img src={SwissFlag} width={80} style={{marginTop: 20, marginLeft: 20}}/>
                        </div>
                        <h2>{data.frontmatter.feature1Title}</h2>
                        <p>{data.frontmatter.feature1Text}</p>
                    </div>
                    </FadeInSection>

                    <FadeInSection>
                    <div className="feature">
                        <div className="roundIcon" style={{backgroundColor: 'darkcyan'}}>
                            <FontAwesomeIcon icon={faHandHoldingDollar} size="4x" color="#fff" style={{marginTop:20, marginLeft:25}}/>
                        </div>
                        <h2>{data.frontmatter.feature2Title}</h2>
                        <p>{data.frontmatter.feature2Text}<sup>3</sup></p>
                    </div>
                    </FadeInSection>

                    <FadeInSection speed="slow">
                    <div className="feature">
                        <div className="roundIcon" style={{backgroundColor: 'purple'}}>
                            <FontAwesomeIcon icon={faChartLine} size="4x" color="#fff" style={{marginTop:28, marginLeft:28}}/>
                        </div>
                        <h2>{data.frontmatter.feature3Title}</h2>
                        <p>{data.frontmatter.feature3Text}</p>
                    </div>
                    </FadeInSection>

                </div>

            </div>





            <div className="sources">
            { /* APA citation style preferred */}
                <p>{sourcesLabel}:</p>
                <ul>
                    <li>
                    [1] Gesundheitsförderung Schweiz (2022). <a
                            href="https://gesundheitsfoerderung.ch/news/betriebliches-gesundheitsmanagement-bgm/job-stress-index-2022"
                            target="_blank">
                        Job-Stress-Index 2022
                        </a>
                    </li>
                    <li>
                    [2] Staatssekretariat für Wirtschaft SECO (2023). <a
                            href="https://www.seco.admin.ch/seco/de/home/Arbeit/Arbeitsbedingungen/gesundheitsschutz-am-arbeitsplatz/Psychosoziale-Risiken-am-Arbeitsplatz.html"
                            target="_blank">
                        Psychosoziale Risiken am Arbeitsplatz
                        </a>
                    </li>
                    <li>
                    [3] University of Chicago, NORC (2021). <a
                            href="https://www.norc.org/research/library/national-safety-council-and-norc-at-the-university-of-chicago-an.html"
                            target="_blank">
                        Why Investing in Mental Health is Good for Business
                        </a>
                    </li>

                </ul>
            </div>

            <Demo lang={pageContext.lang}/>

        </Layout>
  )
}

export default IndexPage

// add headers
export const Head: HeadFC = ({ location, params, data, pageContext }: any ) => {
  // on this page we pick the subtitle from the markdown file as html title
  return <Headers
        lang={pageContext.lang}
        title={data.markdownRemark.frontmatter.pageTitle}
        description={data.markdownRemark.frontmatter.description}
        location={location}
    />
}
