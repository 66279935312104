import * as React from "react"

const text = {
    'de': {
        company: 'Firma',
        employees: 'Anzahl Mitarbeiter',
        name: 'Name',
        role: 'Funktion',
        email: 'E-Mail',
        phone: 'Telefon',
        message: 'Bemerkungen',
        submit: 'Senden',
        sentPath: 'https://ephoria.health/de/gesendet'
    },
    'en': {
        company: 'Company',
        employees: 'Number of Employees',
        name: 'Name',
        role: 'Role',
        email: 'Email',
        phone: 'Phone',
        message: 'Remarks',
        submit: 'Submit',
        sentPath: 'https://ephoria.health/en/sent'
    }
}

const Form = ({lang}: {lang: 'de'|'en'}) => {

    const t = text[lang]

    return (
        <div className="form" style={{margin: '0 auto'}}>
            <form action="https://api.web3forms.com/submit" method="POST">
                <input type="hidden" name="access_key" value="13ea2ae5-958e-4382-beb7-6654f68bd6bd" />
                <input type="hidden" name="redirect" value={t.sentPath}></input>

                <label>{t.company} *</label>
                <input type="text" name="company" required />

                <label>{t.employees} *</label>
                <select name="employees" required>
                    <option value="1-10">1-10</option>
                    <option value="11-50">11-50</option>
                    <option value="51-100">51-100</option>
                    <option value="101-500">101-500</option>
                    <option value="501-1000">501-1000</option>
                    <option value="1000+">1000+</option>
                </select>

                <label>{t.name} *</label>
                <input type="text" name="name" required />

                <label>{t.role}</label>
                <input type="text" name="role" />

                <label>{t.email} *</label>
                <input type="email" name="email" required />

                <label>{t.phone}</label>
                <input type="text" name="phone" />

                <label>{t.message}</label>
                <textarea name="message" rows={5}></textarea>

                <div style={{marginTop: 30}}>
                    { lang == 'de' &&
                        <div style={{float:'left'}}>Mit * gezeichnete Felder sind obligatorisch. Wir verwenden die Informationen, um die Demo zu planen und dich für einen Termin zu kontaktieren. Lese hierzu auch unsere <a href="/de/privacy" target="_blank">Datenschutzerklärung</a>.</div>
                    }
                    { lang == 'en' &&
                        <div>Fields marked with * are mandatory. We use the information provided here to prepare the demo and to contact you. Please take note of our <a href="/en/privacy" target="_blank">privacy policy</a>.</div>
                    }
                </div>
                <div style={{clear: 'both'}}></div>

                <div className="h-captcha" data-captcha="true"></div>

                <button type="submit">{t.submit}</button>

            </form>
            <script src="https://web3forms.com/client/script.js" async defer></script>
        </div>
    )
}


export default  ({lang}: {lang: 'de'|'en'}) => {
    const title = lang == 'de' ? 'Demo anfordern' : 'Request a demo'

    return (
        <div id="demo" className="banner demo">
            <h1>{title}</h1>
            <Form lang={lang} />
        </div>
    )
}